<template>
  <div class="collect_board_wapper">
    <div class="collect_board">
      <div class="collect_info">
        <div class="inner">
          <span class="title">{{data.title ? data.title : '未知画板'}} | </span><span class="cat">所属分类: {{data.cat_title ? data.cat_title : '未知分类'}}</span>
        </div>
        <div class="bar">
          <div class="user_info">
            <img class="avatar" :src="data.pic ? data.pic : 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'">
            <div class="username">{{data.username ? data.username : '未知用户'}}</div>
          </div>
          <div class="status">
            <div>{{data.preview && data.preview[0] !== 'null' ? data.preview.length : 0}} 采集</div>
            <div>{{data.like_num ? data.like_num : 0}} 点赞</div>
          </div>
        </div>
      </div>
      <div class="collect_list">
        <waterfall class="board_waterfall" :col="6" :data="data.preview && data.preview[0] !== 'null' ? data.preview : []">
          <div class="waterfall_item" v-for="(item, index) in (data.preview && data.preview[0] !== 'null' ? data.preview : [])" :key="index">
            <!-- 私有采集 -->
            <div class="waterfall_private" v-if="!item.url">
              <div class="private_content" :style="{ background: bgColor(index) }">
                <i class="iconfont iconsuo"></i>
                <div class="my_waterfall__lock"><span>该采集待公开</span></div>
              </div>
            </div>
            <!-- 公开采集 -->
            <div class="waterfall_public" @click="viewDetails(item.id)" v-if="item.url">
              <div class="public_content__image">
                <div class="inner_box">
                  <img class="image" :src="item.url" alt="图片加载失败" />
                </div>
                <div class="operation collect">
                  <el-button type="danger" size="small" @click.stop>采集</el-button>
                </div>
                <div class="operation sure_wapper" v-if="!item.self">
                  <slot name="buttonTR" :scope="item">
                    <div class="sure">
                      <i class="iconfont iconaixin-kuai"></i>
                    </div>
                  </slot>
                </div>
                <div class="operation edit" v-if="item.self">
                  <el-button type="info" size="small" plain>编辑</el-button>
                </div>
                <div class="operation resemble">
                  <el-button icon="iconfont iconchazhaoxiangsi" size="mini"></el-button>
                </div>
              </div>
            </div>
          </div>
        </waterfall>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lockBgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
      itemId: '',
      data: {},
    }
  },
  methods: {
    bgColor(index) {  // 私有采集随机背景色
      let i = index % this.lockBgColor.length > this.lockBgColor.length ? 1 : index % this.lockBgColor.length;
      return this.lockBgColor[i];
    },
    getBoardData() {
      this.$service.DEFAULT({
        url: '/auth/ppt/board/details',
        method: 'GET',
        params: {
          item_id: this.itemId
        },
      }).then(res => {
        if (res.code == 200) {
          this.data = res.data;
        }
      })
    },
    viewDetails(id) {
      let routeObj = this.$router.resolve({
        path: '/CollectDetails',
        query: { pins: id }
      })
      window.open(routeObj.href, '_blank');
    },
  },
  created() {
    this.itemId = this.$route.query.id;
    this.getBoardData();
  },
}
</script>

<style lang="scss" scoped>
.collect_board_wapper {
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 70px);
  background: #f5f5f5;
  .collect_board {
    width: 90%;
    padding: 10px 0;
    margin: 0 auto;
    .collect_info {
      width: 100%;
      background: #fff;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 4px 8px 0 rgba(0,0,0,.02);
      .inner {
        padding: .6em .75em;
        border-bottom: 1px solid #ededed;
        .title {
          font-size: 20px;
          font-weight: 700;
          color: #444;
        }
        .cat {
          font-size: 14px;
          color: #999;
        }
      }
      .bar {
        padding: 10px 14px;
        height: 40px;
        position: relative;
        background: #fafafa;
        .user_info {
          .avatar {
            float: left;
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 2px;
          }
          .username {
            float: left;
            height: 40px;
            line-height: 40px;
            margin-left: 20px;
          }
        }
        .status {
          height: 60px;
          position: absolute;
          right: 0;
          top: 0;
          > div {
            display: inline-block;
            height: 60px;
            padding: 0 15px;
            line-height: 60px;
            border-left: 1px solid #ededed;
            background: #fff;
          }
        }
      }
    }
    .collect_list {
      .board_waterfall {
        width: 100%;
        padding-top: 20px;
        .waterfall_item {
          box-sizing: border-box;
          padding: 5px;
          margin-bottom: 10px;
          .waterfall_private,
          .waterfall_public {
            box-sizing: border-box;
            width: 100%;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 4px 8px rgba(0,0,0,.02);
            transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
            position: relative;
          }
          .waterfall_private,
          .waterfall_public {
            &:hover {
              transform: translateY(-3px);
              box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 16px 32px -4px rgba(0,0,0,.17);
            }
          }
          .waterfall_private {
            padding-bottom: 100%;
            cursor: pointer;
            .private_content {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: #8dfff7;
              border-radius: inherit;
              &:hover {
                .my_waterfall__lock {
                  display: block;
                }
              }
              .iconsuo {
                font-size: 3em;
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .my_waterfall__lock {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(229,229,229,.75);
                > span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  font-size: 14px;
                  color: #444;
                  font-size: 1.5em;
                  white-space: nowrap;
                }
              }
            }
          }
          .waterfall_public {
            &:hover {
              .public_content__image {
                .operation {
                  display: block;
                }
              }
            }
            .public_content__image {
              min-height: 100px;
              max-height: 800px;
              overflow: hidden;
              cursor: pointer;
              position: relative;
              .image {
                display: block;
                width: 100%;
                border-radius: 6px;
              }
              .operation {
                display: none;
              }
              .collect {
                position: absolute;
                left: 10px;
                top: 10px;
              }
              .sure_wapper {
                position: absolute;
                right: 10px;
                top: 10px;
                .sure {
                  padding: 6px 10px;
                  background-color: #eee;
                  border-radius: 3px;
                  &:hover {
                    background-color: #ccc;
                  }
                }
              }
              .edit {
                position: absolute;
                right: 10px;
                top: 10px;
              }
              .resemble {
                position: absolute;
                right: 10px;
                bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>